import React, { useState, useCallback } from 'react';
import "./FloatingReservationButton.css";
import { Box, Button, Dialog, DialogTitle, DialogContent, MobileStepper, Snackbar, Alert } from '@mui/material';
import DataStep from './ReservationDialogSteps/DataStep';
import ReservationStep from './ReservationDialogSteps/ReservationStep';
import ValidationStep from './ReservationDialogSteps/ValidationStep';
import { GetActivities, CreateBooking } from './BackendConnector';

const FloatingReservationButton = () => {
    const [open, setOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [isCurrentStepValid, setIsCurrentStepValid] = useState(false);
    const [reservationData, setReservationData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        bookings: []
    });
    const [errorMessage, setErrorMessage] = useState(null);

    const steps = [
        <ReservationStep
            key="reservationStep"
            getActivities={GetActivities}
            data={reservationData}
            setData={setReservationData}
            onValidate={setIsCurrentStepValid} />,
        <DataStep
            key="dataStep"
            data={reservationData}
            setData={setReservationData}
            onValidate={setIsCurrentStepValid} />,
        <ValidationStep
            key="validationStep"
            data={reservationData} />
    ];

    const handleOpen = useCallback(() => {
        setActiveStep(0);
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
        handleReset();
    }, []);

    const handleNext = useCallback(() => {
        if (isCurrentStepValid) {
            setActiveStep((prevActiveStep) => Math.min(prevActiveStep + 1, steps.length - 1));
        }
    }, [isCurrentStepValid, steps.length]);

    const handleBack = useCallback(() => {
        setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
    }, []);

    const handleReset = useCallback(() => {
        setActiveStep(0);
        setReservationData({
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            bookings: []
        });
    }, []);

    const handleSubmit = useCallback(() => {
        CreateBooking(reservationData)
            .then(() => {
                handleClose();
            })
            .catch((error) => {
                console.error("Error submitting data", error);
                setErrorMessage("Er is een fout opgetreden bij het indienen van de reservering. Probeer het opnieuw. Indien dit blijft voorkomen, gelieve ons te contacteren.");
            });
    }, [CreateBooking, reservationData, handleClose]);

    const handleSnackbarClose = () => {
        setErrorMessage(null);
    };

    return (
        <Box className="reservation-location">
            <Button variant="contained" color="primary" onClick={handleOpen} disabled>
                Reservering Maken
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                sx={{
                    '& .MuiDialog-container': {
                        alignItems: 'flex-start',
                    }
                }}
            >
                <DialogTitle>Reservering Maken</DialogTitle>
                <DialogContent dividers={true} sx={{
                    overflowY: 'auto',
                    maxHeight: 'calc(100vh - 150px)',
                }}>
                    {steps[activeStep]}
                </DialogContent>
                <MobileStepper
                    variant="progress"
                    steps={steps.length}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                            disabled={!isCurrentStepValid}>
                            {activeStep === steps.length - 1 ? 'Reserveer' : 'Volgende'}
                        </Button>
                    }
                    backButton={
                        <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            style={{ marginRight: '8px' }}>
                            Terug
                        </Button>
                    }
                    sx={{
                        position: 'sticky',
                        bottom: 0,
                        backgroundColor: 'background.paper',
                    }}
                />
            </Dialog>
            {errorMessage && (
                <Snackbar
                    open={!!errorMessage}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%', zIndex: 1500 }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            )}
        </Box>
    );
};

export default FloatingReservationButton;
