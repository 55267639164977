import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Box, Typography } from '@mui/material';

const DataStep = ({ data, setData, onValidate }) => {
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: ''
    });

    const [touchedFields, setTouchedFields] = useState({
        firstName: false,
        lastName: false,
        phoneNumber: false,
        email: false
    });

    const validators = {
        firstName: (value) => value.trim() ? '' : 'Voornaam is verplicht',
        lastName: (value) => value.trim() ? '' : 'Achternaam is verplicht',
        phoneNumber: (value) =>
            /^(\\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value)
                ? ''
                : 'Ongeldig telefoonnummer',
        email: (value) =>
            /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value.toLowerCase())
                ? ''
                : 'Ongeldig e-mailadres'
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name in validators) {
            setData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
            setTouchedFields((prevFields) => ({
                ...prevFields,
                [name]: true,
            }));
        }
    };

    const handleInputValidation = useCallback(() => {
        const newErrors = Object.keys(validators).reduce((acc, key) => {
            const error = validators[key](data[key]);
            return { ...acc, [key]: error };
        }, {});

        setErrors(newErrors);
        return !Object.values(newErrors).some((error) => error !== '');
    }, [data]);

    useEffect(() => {
        if (Object.values(data).some((value) => value !== '')) {
            const isValid = handleInputValidation();
            onValidate(isValid);
        }
    }, [data, handleInputValidation, onValidate]);

    return (
        <Box p={3}>
            <Typography variant="h6" gutterBottom>
                Persoonlijke Informatie
            </Typography>
            {Object.keys(validators).map((field) => (
                <TextField
                    key={field}
                    margin="normal"
                    label={
                        field === 'firstName' ? 'Voornaam' :
                            field === 'lastName' ? 'Achternaam' :
                                field === 'phoneNumber' ? 'Telefoonnummer' : 'E-mailadres'
                    }
                    name={field}
                    value={data[field]}
                    onChange={handleInputChange}
                    error={touchedFields[field] && !!errors[field]}
                    helperText={touchedFields[field] ? errors[field] : ''}
                    fullWidth
                />
            ))}
        </Box>
    );
};

export default DataStep;
