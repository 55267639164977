import React from 'react';
import { Box, Card, CardContent, List, ListItem, Typography } from '@mui/material';

const InfoListItem = ({ label, value }) => (
    <ListItem sx={{ py: 0.25 }}>  {/* Reduced padding to make space smaller */}
        <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>
            {label}
        </Typography>
        <Typography variant="body1">
            {value}
        </Typography>
    </ListItem>
);

const CommonCard = ({ title, children }) => (
    <Card variant="outlined" sx={{ mb: 2 }}>
        <CardContent sx={{ p: 1.5 }}> {/* Reduced padding around the content */}
            {title && (
                <Typography variant="subtitle1" sx={{ mb: 0.5, fontWeight: 'bold' }}> {/* Reduced bottom margin */}
                    {title}
                </Typography>
            )}
            <List sx={{ p: 0 }}>  {/* Removed padding from List */}
                {children}
            </List>
        </CardContent>
    </Card>
);

const ValidationStep = ({ data }) => {
    return (
        <Box p={3}>
            <Typography variant="h5" gutterBottom>
                Bevestig uw reservatie
            </Typography>

            <Typography variant="h6" gutterBottom>
                Persoonlijke Informatie
            </Typography>
            <CommonCard>
                <InfoListItem label="Voornaam:" value={data.firstName} />
                <InfoListItem label="Achternaam:" value={data.lastName} />
                <InfoListItem label="Telefoonnummer:" value={data.phoneNumber} />
                <InfoListItem label="E-mailadres:" value={data.email} />
            </CommonCard>

            <Typography variant="h6" gutterBottom>
                Boekingsdetails
            </Typography>
            {data.bookings
                .sort((a, b) => a.hour.localeCompare(b.hour))
                .map((booking, index) => (
                    <CommonCard key={index}>
                        <InfoListItem label="Activiteit:" value={booking.activity} />
                        <InfoListItem label="Datum:" value={booking.date.toLocaleDateString()} />
                        <InfoListItem label="Uur:" value={booking.hour} />
                        <InfoListItem label="Aantal personen:" value={booking.numberOfPeople.toString()} />
                    </CommonCard>
                ))}
        </Box>
    );
};

export default ValidationStep;
