import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, Button, IconButton, TextField, MenuItem, Paper, Grid, ButtonGroup } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';

const ReservationStep = ({ getActivities, data, setData, onValidate }) => {
    const DEFAULT_EARLIEST = 8;
    const DEFAULT_LATEST = 20;
    const [activities, setActivities] = useState([]);
    const [newBooking, setNewBooking] = useState({
        activity: '',
        date: new Date(),
        hour: '',
        numberOfPeople: 1,
    });
    const [selectedSlot, setSelectedSlot] = useState(null);

    const hasExistingBookings = useMemo(() => data.bookings.length > 0, [data.bookings]);
    const lockedDate = useMemo(() => (hasExistingBookings ? data.bookings[0].date : null), [hasExistingBookings, data.bookings]);

    const currentActivity = useMemo(
        () => activities.find((a) => a.name === newBooking.activity),
        [activities, newBooking.activity]
    );

    const isAddButtonDisabled = newBooking.activity === '' || newBooking.hour === '';

    const addBooking = () => {
        const updatedBookings = [...data.bookings, { ...newBooking }];
        setData({ ...data, bookings: updatedBookings });
        setNewBooking({ activity: '', date: newBooking.date, hour: '', numberOfPeople: 1 });
        setSelectedSlot(null);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const removeBooking = (index) => {
        const updatedBookings = data.bookings.filter((_, i) => i !== index);
        setData({ ...data, bookings: updatedBookings });
    };

    const findMaxNumberOfPeople = (activityName) => {
        return activities.find((activity) => activity.name === activityName)?.maxNumberOfPeople || 1;
    };

    const handleNewBookingChange = (e) => {
        const { name, value } = e.target;
        if (name === 'activity') {
            const maxNumberOfPeople = findMaxNumberOfPeople(value);
            setNewBooking({ ...newBooking, [name]: value, numberOfPeople: Math.min(newBooking.numberOfPeople, maxNumberOfPeople) });
        } else if (name === 'numberOfPeople') {
            setNewBooking({ ...newBooking, [name]: Math.max(1, parseInt(value, 10)) });
        } else {
            setNewBooking({ ...newBooking, [name]: value });
        }
    };

    const handleDateChange = (newValue) => {
        if (newValue) {
            const start = newValue.startOf('month').toDate();
            const end = newValue.endOf('month').toDate();

            if (start.getMonth() !== newBooking.date.getMonth()) {
                fetchActivities(start, end);
            }

            if (!hasExistingBookings) {
                setNewBooking({ ...newBooking, date: newValue.toDate() });
            }
        }
    };

    const handleHourChange = (newValue) => {
        setNewBooking({ ...newBooking, hour: newValue ? newValue.format('HH:mm') : '' });
    };

    const handleSlotChange = (slot) => {
        setSelectedSlot(slot);
        setNewBooking({ ...newBooking, hour: slot.startTime });
    };

    const fetchActivities = async (start, end) => {
        const activities = await getActivities(start, end);
        setActivities(activities);
    };

    useEffect(() => {
        const today = new Date();
        const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        fetchActivities(today, endOfMonth);
    }, []);

    useEffect(() => {
        onValidate(data.bookings.length > 0);
    }, [data.bookings, onValidate]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box p={3}>
                <Typography variant="h6" gutterBottom>
                    Reservatiegegevens
                </Typography>
                {data.bookings.map((booking, index) => (
                    <Paper key={index} elevation={3} sx={{ mb: 2, position: 'relative', overflow: 'hidden', borderRadius: 2, backgroundColor: 'grey.100' }}>
                        <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                            <IconButton onClick={() => removeBooking(index)} size="small">
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box sx={{ p: 1.5 }}>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item xs={12} sm>
                                    <Typography>{booking.activity}</Typography>
                                </Grid>
                                <Grid item xs={12} sm>
                                    <Typography>{dayjs(booking.date).format('DD/MM/YYYY')}</Typography>
                                </Grid>
                                <Grid item xs={12} sm>
                                    <Typography>{booking.hour}</Typography>
                                </Grid>
                                <Grid item xs={12} sm>
                                    <Typography>{`${booking.numberOfPeople} personen`}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                ))}
                <TextField
                    select
                    label="Activiteit"
                    value={newBooking.activity}
                    onChange={handleNewBookingChange}
                    name="activity"
                    margin="normal"
                    fullWidth
                >
                    {activities.map((activity) => (
                        <MenuItem key={activity.name} value={activity.name}>
                            {activity.name}
                        </MenuItem>
                    ))}
                </TextField>
                <DatePicker
                    label="Datum"
                    value={hasExistingBookings ? dayjs(lockedDate) : dayjs(newBooking.date)}
                    onChange={handleDateChange}
                    disabled={hasExistingBookings}
                    minDate={dayjs().startOf('day')}
                    slotProps={{ textField: { fullWidth: true, margin: 'normal' } }}
                />
                {hasExistingBookings && (
                    <Typography variant="caption" color="textSecondary" sx={{ mt: 1 }}>
                        Verwijder bovenstaande reservaties om de datum aan te passen.
                    </Typography>
                )}
                {newBooking.activity &&
                    (currentActivity?.slots ? (
                        <Box sx={{ my: 2 }}>
                            <Typography variant="subtitle1">Kies een tijdslot:</Typography>
                            <ButtonGroup variant="contained" aria-label="outlined primary button group">
                                {currentActivity.slots.map((slot, index) => (
                                    <Button
                                        key={index}
                                        onClick={() => handleSlotChange(slot)}
                                        variant={selectedSlot === slot ? 'contained' : 'outlined'}
                                    >
                                        {`${slot.startTime} - ${slot.endTime}`}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </Box>
                    ) : (
                        <TimePicker
                            label="Uur"
                            minTime={dayjs().set('hour', currentActivity?.earliestTime || DEFAULT_EARLIEST)}
                            maxTime={dayjs().set('hour', currentActivity?.latestTime || DEFAULT_LATEST)}
                            value={newBooking.hour ? dayjs(newBooking.hour, 'HH:mm') : null}
                            onChange={handleHourChange}
                            ampm={false}
                            slotProps={{ textField: { fullWidth: true, margin: 'normal' } }}
                        />
                    ))}
                <TextField
                    select
                    label="Aantal personen"
                    value={newBooking.numberOfPeople}
                    onChange={handleNewBookingChange}
                    name="numberOfPeople"
                    margin="normal"
                    fullWidth
                >
                    {[...Array(findMaxNumberOfPeople(newBooking.activity)).keys()].map((n) => (
                        <MenuItem key={n + 1} value={n + 1}>
                            {n + 1}
                        </MenuItem>
                    ))}
                </TextField>
                <Button onClick={addBooking} variant="contained" sx={{ my: 2 }} disabled={isAddButtonDisabled}>
                    Boeking toevoegen
                </Button>
            </Box>
        </LocalizationProvider>
    );
};

export default ReservationStep;
